export default [

    {
        path: "/homePage",
        name: "homePage",
        externalLinks:false,
        trendsParams:{},
        meta: {
            title: "首页",
            icon: "\ue61d",
            show: true
        },
        children: []
    },
    // {
    //     path: "/BindEmployees",
    //     name: "BindEmployees",
    //     externalLinks:false,
    //     trendsParams:{},
    //     meta: {
    //         title: "账号管理",
    //         icon: "\ue60a",
    //         show: true
    //     },
    //     children: [
    //         {
    //             path: "/BindEmployees/StaffManagement",
    //             name: "StaffManagement",
    //             externalLinks:false,
    //             trendsParams:{},
    //             meta: { title: "账号信息", show: true },
    //             children: []
    //         },
    //         {
    //             path: "/BindEmployees/employeeInformation",
    //             name: "employeeInformation",
    //             externalLinks:false,
    //             trendsParams:{},
    //             meta: { title: "员工信息", show: true },
    //             children: []
    //         },
    //         {
    //             path: "/BindEmployees/RechargeRecord",
    //             name: "RechargeRecord",
    //             externalLinks:false,
    //             trendsParams:{},
    //             meta: { title: "充值记录", show: true },
    //             children: []
    //         },
    //     ]
    // },
    {
        path: "/dataAnalysis",
        name: "dataAnalysis",
        externalLinks:false,
        trendsParams:{},
        meta: {
            title: "数据分析",
            icon: "\ue7b9",
            show: true
        },
        children: [{
            path: "/dataAnalysis/analysIndex",
            name: "analysIndex",
            externalLinks:false,
            trendsParams:{},
            meta: { title: "视频列表", show: true },
            children: []
        },]
    },
    {
        path: "/ReleaseRecords",
        name: "ReleaseRecords",
        externalLinks:false,
        trendsParams:{},
        meta: {
            title: "发布记录",
            icon: "\ue61d",
            show: true
        },
        children: [{
            path: "/ReleaseRecords/recordsIndex",
            name: "recordsIndex",
            externalLinks:false,
            trendsParams:{},
            meta: { title: "发布记录", show: true },
            children: []
        },]
    },
]