import {createRouter, createWebHashHistory} from 'vue-router'
import routes from "@/router/routes";


const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: "/404Not",
            name: "404Not",
            component: () => import("@/views/notFound/404Not")
        },
        {
            path: '/',
            name: 'login',
            component: () => import('@/views/login/loginIndex'),
        },
        {
            path: '/home',
            name: 'home',
            component: () => import('@/components/pageOut'),
            redirect: "/homePage",
            children: []
        },
    ]
})

// 处理路由数据，动态添加路由
function filterRoute(route, fPath = "") {
    route.forEach(item => {
        const {path, meta, children, name, trendsParams, externalLinks} = item
        if (children.length) {
            filterRoute(children, path)
        } else {
            if (!externalLinks) {
                router.addRoute("home", {
                    path: path + Object.keys(trendsParams).reduce((preVal, item) => preVal += ("/:" + item), ""),
                    name,
                    meta: {
                        ...meta,
                        fPath
                    },
                    component:()=>import(`@/views${path}/index.vue`)
                })
            }
        }
    })
}

// modules[`@/views${path}/index.vue`]
filterRoute(routes)
export default router
