import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/style/index.less'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import '@/assets/font_4311296_ifkmviezzvg/iconfont.css'

import * as echarts from 'echarts'
import { createPinia } from 'pinia'
const app=createApp(App)
app.config.globalProperties.$echarts=echarts
createApp(App).use(ElementPlus).use(store).use(createPinia()).use(router).mount('#app')
