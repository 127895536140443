<template>
    <el-config-provider  :locale="zhCn">
        <router-view/>
    </el-config-provider>
</template>
<script setup>
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
const debounce = (fn, delay) => {

    let timer = null;

    return function () {

        let context = this;

        let args = arguments;

        clearTimeout(timer);

        timer = setTimeout(function () {

            fn.apply(context, args);

        }, delay);

    }

}

// 解决ERROR ResizeObserver loop completed with undelivered notifications.

//问题的

const _ResizeObserver = window.ResizeObserver;

window.ResizeObserver = class ResizeObserver extends _ResizeObserver {

    constructor(callback) {

        callback = debounce(callback, 16);

        super(callback);

    }

}
// import en from 'element-plus/dist/locale/en.mjs'
// import {computed, ref} from "vue";
// const language = ref('zh-cn')
// const toggle = () => {
//     language.value = language.value === 'zh-cn' ? 'en' : 'zh-cn'
// }
// const locale = computed(() => (language.value === 'zh-cn' ? zhCn : en))
</script>
<style lang="less" scoped>

</style>
